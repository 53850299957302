import axios from "axios";
import app from "../main";
import router from "@/router";
import Swal from "sweetalert2";

window.axios = axios
/** baseUrl Local */
// axios.defaults.baseURL = 'http://localhost:8000/api/'

/** baseURL Dummy or Devlop*/
// axios.defaults.baseURL = 'https://api-oaktree-administrator.rimbahouse.com/public/api/'

/** baseURL Staging */
// axios.defaults.baseURL = 'https://bean-api.rimbahouse.com/public/api/'

/** baseURL Production */
axios.defaults.baseURL = 'https://api-admin.oaktree.id/api/'


window.axios.defaults.headers.common = {
  Authorization: "Bearer " + localStorage.getItem("token"),
};

window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

window.axios.interceptors.request.use(function (config) {
  app.$Progress.start(); // for every request start the progress
  return config;
}, function (error) {
  return Promise.reject(error);
}
);

window.axios.interceptors.response.use(function (response) {
  app.$Progress.finish(); // finish when a response is received
  return response;
},
  (error) => {
    console.log(error);
    if (error.response.status == 401) {
      router.push({ name: "admin-login" });
      Swal.fire(
        error.response.status,
        "Harap login terlebih dahulu!",
        "warning"
      );
    } else if (error.response.status == 500) {
      // Swal.fire(error.response.status, "Terjadi Kesalahan, harap hubungi Admin", "error");
      Swal.fire(
        error.response.status,
        (module.message = error.response.data.message),
        "error"
      );
    } else if (error.response.status == 400) {
      // Swal.fire(error.response.status, "Terjadi Kesalahan, harap hubungi Admin", "error");
      Swal.fire(
        error.response.status,
        (module.message = error.response.data.message),
        "error"
      );
    } else if (error.response.status == 422) {
      Swal.fire(error.response.status, error.response.data.message, "error");
    }

    return Promise.reject(error);
  }
);
